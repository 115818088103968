import '../css/AccountSettings.css'
import {Button, Form} from "react-bootstrap";
import {Formik} from "formik";
import * as yup from "yup";
import firebase from 'firebase/app';
import {useFirestore, useUser} from "reactfire";

function AccountSettings() {

    const {data: user} = useUser();
    const firestore = useFirestore();

    const schema = yup.object().shape({
        newEmail: yup.string().email().required(),
        newPassword: yup.string().matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Must Contain 8 Characters, one Uppercase, one Lowercase, one Number and one special case character"),
        newPasswordConfirm: yup.string().test(
            'confirm-password',
            'Passwords do not match',
            function (password) {
                return this.parent.newPassword == password;
             }),
        currentPassword: yup.string().required().test(
            'is-user-reauthenticated',
            'Wrong password given',
            async (password) =>  {
                try{
                    const credential = firebase.auth.EmailAuthProvider.credential(
                        user.email,
                        password
                    );
                    await user.reauthenticateWithCredential(credential);
                    return true;
                } catch (e){
                    return false;
                }
            })
        });

    const updateAccount = async (values, {setSubmitting, setStatus}) => {
        setStatus();
        try {
            const email = values.newEmail;
            const password = values.newPassword;

            if(email?.length > 0 && email !== user.email){
                await user.updateEmail(email);
                await firestore
                    .collection('customers')
                    .doc(user.uid)
                    .update({email: email});

            }

            if(password?.length > 0){
                await user.updatePassword(password);
            }

            window.location.assign('/');

        } catch (e) {
            setSubmitting(false);
            setStatus({
                message: e.message,
                type: 'error',
            });
        }
    }

    return (
        <div className="wrapper bg-white mt-sm-5">
            <h4 className="pb-4 border-bottom">Αλλαγή στοιχείων</h4>
            <p>Μπορείτε να αλλάξετε προαιρετικά όποιο στοιχείο επιθυμείτε.</p>
            <Formik
                validationSchema={schema}
                validateOnChange={false}
                onSubmit={updateAccount}
                enableReinitialize={true}
                initialValues={{
                    newEmail: user?.email || '',
                    newPassword: '',
                    currentPassword: '',
                }}>
                {({
                      handleSubmit, handleChange,
                      values, errors, status
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group controlId="accountEmail">
                            <Form.Label>Νέα διεύθυνση ηλ. ταχυδρομείου</Form.Label>
                            <Form.Control
                                type="email"
                                name="newEmail"
                                value={values.newEmail}
                                onChange={handleChange}
                                isInvalid={!!errors.newEmail}
                                placeholder="Email"
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.newEmail}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="accountNewPassword">
                            <Form.Label>Νέος κωδικός πρόσβασης</Form.Label>
                            <Form.Control
                                type="password"
                                name="newPassword"
                                value={values.newPassword}
                                onChange={handleChange}
                                isInvalid={!!errors.newPassword}
                                placeholder="Password"
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.newPassword}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="accountNewPasswordConfirm">
                            <Form.Label>Επιβεβαίωση νέου κωδικού πρόσβασης</Form.Label>
                            <Form.Control
                                type="password"
                                name="newPasswordConfirm"
                                value={values.newPasswordConfirm}
                                onChange={handleChange}
                                isInvalid={!!errors.newPasswordConfirm}
                                placeholder="Password"
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.newPasswordConfirm}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="accountCurrentPassword" className="pt-3 border-top">
                            <Form.Label>Τρέχων κωδικός πρόσβασης</Form.Label>
                            <Form.Control
                                type="password"
                                name="currentPassword"
                                value={values.currentPassword}
                                onChange={handleChange}
                                isInvalid={!!errors.currentPassword}
                                placeholder="Password"
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.currentPassword}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <p>
                            <Button
                                className="main-button my-2 btn-block"
                                variant="primary"
                                type="submit">
                                Ενημέρωση στοιχείων
                            </Button>
                            <Button
                                className="main-button my-2 btn-block"
                                variant="danger"
                                type="button"
                                href="/">
                                Eπιστροφή στην αρχική
                            </Button>
                        </p>
                        {status && status.message && (
                            <div className="error-text">{status.message}</div>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default AccountSettings;

