import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { FirebaseAppProvider } from 'reactfire'
import Progress from "react-progress-2";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import './css/index.css'
import 'react-progress-2/main.css'
import App from './App';
import { config } from './config'

ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={config[process.env.REACT_APP_ENV].firebaseConfig}>
      <BrowserRouter>
        <Progress.Component/>
        <Elements stripe={loadStripe(config[process.env.REACT_APP_ENV].STRIPE_PUBLISHABLE_KEY)}>
          <App/>
        </Elements>
      </BrowserRouter>
  </FirebaseAppProvider>
, document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
