import { Container, Dropdown, DropdownButton, Nav, Navbar} from "react-bootstrap";
import 'firebase/auth';
import 'firebase/functions';
import {useAuth, useFunctions, useSigninCheck, useUser} from "reactfire";
import {config} from "../../../config";
import Progress from "react-progress-2";
import {useHistory} from "react-router-dom";
import React, { useState } from "react";

function AccountMenu(){
    const history = useHistory();
    const { status, data: signInCheckResult } = useSigninCheck();
    const auth = useAuth();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const {data: user} = useUser();
    const functions = useFunctions();
    functions.region = config[process.env.REACT_APP_ENV].CLOUD_FUNCTIONS_LOCATION;

    async function createBillingPortal() {
        Progress.show();
        const functionRef = functions.httpsCallable('createPortalLink ');

        const { data } = await functionRef({ returnUrl: window.location.origin });
        Progress.hide();
        window.location.assign(data.url);
    }

    if (status === 'loading') {
        return null;
    }

    if (signInCheckResult.signedIn === true) {
        return (
            <button className="button-logged">
                {user && <div className={'px-2 mobile-hide'}>{user.email}</div>}
                <DropdownButton menuAlign={{ lg: 'left' }} title="Λογαριασμός" >
                    <Dropdown.Item href="/account">Ρυθμίσεις λογαριασμού</Dropdown.Item>
                    <Dropdown.Item onClick={() => createBillingPortal()}>Διαχείριση συνδρομών</Dropdown.Item>
                    <Dropdown.Item onClick={() => auth.signOut()}>Αποσύνδεση</Dropdown.Item>
                </DropdownButton>
            </button>    
        );
    } else {
        return (
            <button className="button" onClick={() => history.push('/login')}>
                <div className="text-wrapper-2">Είσοδος / Εγγραφή</div>
            </button>
        );
    }
}

function Menu(){
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
    return (
        <>
            <div className="header">
                <a className="icon-logo" href="/">
                    <div className="frame">
                        <img className="nmcpicon" alt="Nmcpicon" src="./assets/images/NMCPicon.png" />
                    </div>
                    <div className="text-wrapper">NMCP</div>
                </a>
                <div className="group">
                    <div className="view">
                        <a className="div" href="/">Αρχική</a>
                    </div>
                    <div className="div-wrapper">
                        <a className="div" href="/#abilities">Δυνατότητες</a>
                    </div>
                    <div className="div-wrapper">
                        <a className="div" href="/#pricing">Τιμολόγηση</a>
                    </div>
                    <div className="div-wrapper">
                        <a className="div" href="/#contact">Επικοινωνία</a>
                    </div>
                    <div className="div-wrapper">
                        <a className="div" href="https://raw.githubusercontent.com/N-M-C-P/Versions/main/NMCP%20-%20manual.pdf" target="_blank" rel="noopener noreferrer">Εγχειρίδιο Χρήσης</a>
                    </div>
                </div>
                <AccountMenu/>
            </div>
        </>
    );
}

export default Menu;
