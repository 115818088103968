import '../css/NotFoundPage.css'

function NotFound() {
    return (
        <div id="notfound">
            <div className="notfound">
                <div className="notfound-404">
                    <h1>404</h1>
                </div>
                <h2>Ουπς! Δεν βρήκαμε κάτι</h2>
                <p>Η σελίδα που ψάχνετε μπορει να έχει αφαιρεθεί, να έχει αλλάξει ονομασία ή να είναι προσωρινά μη
                    διαθέσιμη. <a href="/">Επιστροφή στην αρχική σελίδα</a></p>
            </div>
        </div>
    );
}

export default NotFound;