import {useSigninCheck} from "reactfire";
import {Route, Redirect} from "react-router-dom";

function PrivateRoute({ children, ...rest }) {
    const {status, data: signInCheckResult} = useSigninCheck();

    if(status  === 'loading'){
        return null;
    }

    return (
        <Route
            {...rest}
            render={({ location }) =>
                signInCheckResult.signedIn ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

export default PrivateRoute;