import * as yup from "yup";
import React from "react";
import 'firebase/auth';
import 'firebase/firestore';
import {useAuth, useFirestore} from 'reactfire';
import Menu from "../components/pages/Fragments/Menu";
import Footer from "../components/pages/Fragments/Footer";
import '../css/header.css';
import '../css/footer.css';
import '../css/Register.css';
import {Button, Nav, Form} from 'react-bootstrap';
import { Formik } from "formik";
import { useState } from 'react';
import { useHistory } from "react-router-dom";

function Register(props) {
    const history = useHistory();
    const auth = useAuth();
    const firestore = useFirestore();
    const [isCompany, setIsCompany] = useState(false);

    const schema = yup.object().shape({
        email: yup.string().email().required(),
        password: yup.string().required().matches(
            /^.{6}$/,
            "Ο κωδικός πρόσβασης θα πρέπει να αποτελείται τουλάχιστον από 6 χαρακτήρες"),
        confirmPassword: yup.string().required().oneOf([yup.ref('password')], 'Οι κωδικοί πρόσβασης πρέπει να ταιριάζουν'),
        fullName: yup.string().when((fullName, schema) => {
            return isCompany ? schema : schema.required();
        }),
        businessName: yup.string().when((businessName, schema) => {
            return isCompany ? schema.required() : schema;
        }),
        businessStreet: yup.string().when((businessStreet, schema) => {
            return isCompany ? schema.required() : schema;
        }),
        businessArea: yup.string().when((businessArea, schema) => {
            return isCompany ? schema.required() : schema;
        }),
        businessZipCode: yup.string().when((businessZipCode, schema) => {
            return isCompany ? schema.matches(/^\d{5}$/).required() : schema;
        }),
        businessVAT: yup.string().when((businessVAT, schema) => {
            return isCompany ? schema.test('isValidAFM', 'Αυτό το AFM δεν είναι έγκυρο', (value) => validateAFM(value)) : schema
        }),
        terms: yup.bool().required().oneOf([true], 'Οι όροι πρέπει να γίνουν αποδεκτοί'),
    });

    function validateAFM(afm = '') {

        if (!afm.match(/^\d{9}$/) || afm == '000000000') {
            return false;
        }

        let m = 1, sum = 0;
        for (let i = 7; i >= 0; i--) {
            m *= 2;
            sum += afm.charAt(i) * m;
        }

        return (sum % 11 % 10) == afm.charAt(8);

    }

    const signUp = async (values, {setSubmitting, setStatus}) => {
        setStatus();
        try {
            
            const email = values.email;
            const password = values.password;

            const userCredentials = await auth.createUserWithEmailAndPassword(email, password);
            const {user} = userCredentials;

            let userInfo;
            if (isCompany) {
                userInfo = {
                    "name": values.businessName,
                    "address": {
                        "city": values.businessArea,
                        "country": 'GR',
                        "line1": values.businessStreet,
                        "postal_code": values.businessZipCode
                    },
                    "tax_id": values.businessVAT,
                    "isCompany": true
                }
            } else {
                userInfo = {
                    "name": values.fullName,
                    "isCompany": false
                }
            }
            await firestore
                .collection('customers')
                .doc(user.uid)
                .set(userInfo, {merge: true});

            await user.sendEmailVerification();

            history.push("/#");
        } catch (e) {
            console.log(e);
            setSubmitting(false);
            setStatus({
                message: e.message,
                type: 'error',
            });
        }
    }
    
    return (
        <Formik
            validationSchema={schema}
            validateOnChange={false}
            onSubmit={signUp}
            initialValues={{
                fullName: '',
                email: '',
                password: '',
                confirmPassword: '',
                businessName: '',
                businessStreet: '',
                businessArea: '',
                businessZipCode: '',
                businessVAT: '',
                terms: false
            }}>
            {({
                  handleSubmit, handleChange,
                  values, errors, status, resetForm
              }) => (
            <div className="Register">
            <Menu/>
            <div className="Register-content" >
                <div className="frame">
                    <div className="Register-form">
                            <Form className="form" onSubmit={handleSubmit}>   
                                    <div className="text-wrapper">Καλως ήρθατε</div>     
                                    <Form.Group controlId="signUpEmail">

                                        <Form.Group >
                                            <Form.Label className="label">Διεύθυνση ηλ. ταχυδρομείου</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                isInvalid={!!errors.email}
                                                placeholder="Email"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.email}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group controlId="signUpPassword">
                                            <Form.Label className="label">Κωδικός πρόσβασης</Form.Label>
                                            <Form.Control
                                                type="password"
                                                name="password"
                                                value={values.password}
                                                onChange={handleChange}
                                                isInvalid={!!errors.password}
                                                placeholder="Password"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.password}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group controlId="confirmSignUpPassword">
                                            <Form.Label className="label">Επιβεβαίωση Κωδικού</Form.Label>
                                            <Form.Control
                                                type="password"
                                                name="confirmPassword"
                                                value={values.confirmPassword}
                                                onChange={handleChange}
                                                isInvalid={!!errors.confirmPassword}
                                                placeholder="Password"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.confirmPassword}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label className="label">Είμαι:</Form.Label>
                                                
                                            <Nav variant="pills" defaultActiveKey="consumer">
                                            <Nav.Item>
                                                <Nav.Link className="button-consumer" eventKey="consumer" onClick={() => {
                                                    setIsCompany(false);
                                                    resetForm({values});
                                                }}>Ιδιώτης</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link className="button-company" eventKey="company" onClick={() => {
                                                    setIsCompany(true);
                                                    resetForm({values});
                                                }}>Εταιρία</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                            
                                        </Form.Group>
                                        {
                                            isCompany && //If user is an individual
                                            <div>
                                                <Form.Group controlId="businessName">
                                                    <Form.Label className="label">Επωνυμία επιχείρησης</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="businessName"
                                                        value={values.businessName}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.businessName}
                                                        placeholder="Επωνυμία επιχείρησης"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.businessName}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group controlId="businessStreet">
                                                    <Form.Label className="label">Οδός</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="businessStreet"
                                                        value={values.businessStreet}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.businessStreet}
                                                        placeholder="Οδός"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.businessStreet}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group controlId="businessArea">
                                                    <Form.Label className="label">Περιοχή</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="businessArea"
                                                        value={values.businessArea}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.businessArea}
                                                        placeholder="Περιοχή"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.businessArea}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group controlId="businessZipCode">
                                                    <Form.Label className="label">Ταχυδρομικός κώδικας</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="businessZipCode"
                                                        value={values.businessZipCode}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.businessZipCode}
                                                        placeholder="ΤΚ"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.businessZipCode}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group controlId="businessVAT">
                                                    <Form.Label className="label">ΑΦΜ</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="businessVAT"
                                                        value={values.businessVAT}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.businessVAT}
                                                        placeholder="ΑΦΜ"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.businessVAT}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        }                
                                        {
                                            !isCompany && //If user is a company
                                            <Form.Group controlId="signUpFullName">
                                                <Form.Label className="label">Ονοματεπώνυμο</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="fullName"
                                                    value={values.fullName}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.fullName}
                                                    placeholder="Full Name"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.fullName}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        }           
                                        <Form.Group>
                                            <Form.Check
                                                name="terms"
                                                label={
                                                    <div className="label">
                                                        Συμφωνώ με τους <a href="/tos">Όρους Χρήσης</a> και την <a href="/privacy-policy">Πολιτική
                                                        Απορρήτου</a>
                                                    </div>
                                                }
                                                onChange={handleChange}
                                                isInvalid={!!errors.terms}
                                                feedback={errors.terms}
                                            />
                                        </Form.Group>
                                            <Button className="main-button-instance"  onClick={handleSubmit}>
                                                        <div className="ButtonText" 
                                                            variant="primary"
                                                            type="submit">Eγγραφή</div>
                                            </Button>
                                            {status && status.message && (
                                            <div className="error-text">{status.message}</div>
                                            )}       
                                    </Form.Group>
                            </Form>
                        </div>
                        <div className="element-wrapper">
                            <img className="element" alt="Element" src="./assets/images/Signin_illu.png" />
                        </div>    
                    </div>
                </div>
                <Footer/>
            </div>)}
        </Formik>
      );
    };


export default Register;