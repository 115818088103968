//ALL KEYS HERE ARE PUBLIC, NEVER PLACE A SECRET

const config = {
    dev: {
        firebaseConfig: {
            apiKey: "AIzaSyB21URuvpMbGytNLSnJqyRIq_wEL8i0VKY",
            authDomain: "nmcp-dev.firebaseapp.com",
            projectId: "nmcp-dev",
            storageBucket: "nmcp-dev.appspot.com",
            messagingSenderId: "380607160866",
            appId: "1:380607160866:web:aa1d115978f11fbe25e07b",
            measurementId: "G-N9BZHMY4LK"
        },
        MAPS_API_KEY: 'AIzaSyALZFoO55632x_YZQWF7YixwuhRzCv9KaU',
        CLOUD_FUNCTIONS_LOCATION: 'europe-west3',
        STRIPE_PUBLISHABLE_KEY: 'pk_test_51IpiQ4EI3HJ29RMKAmHfgJRYHzvRbv5b0XhuHA6z2CQwrfdgGkSwFkzel1FIINLAuuwN292INkji0Zcw9nGCWIaj00w2Scx3uF',
        product : {
            prices: [
                {
                    id: 'price_1IxdyDEI3HJ29RMKI2iaIuVx',
                    description: 'Ανά Μήνα',
                    first_unit_price: 24.19,
                    extra_unit_price: 8.06,
                    vat_percentage: 0.24
                }, {
                    id: 'price_1IxdyDEI3HJ29RMKy33kzeoY',
                    description: 'Ανά Τρίμηνο',
                    first_unit_price: 64.51,
                    extra_unit_price: 20.16,
                    vat_percentage: 0.24
                }, {
                    id: 'price_1LByEUEI3HJ29RMK9jhcXbTq',
                    description: 'Ανά Έτος',
                    first_unit_price: 217.74,
                    extra_unit_price: 64.51,
                    vat_percentage: 0.24
                }
            ],
            tax_id: 'txr_1IxvpZEI3HJ29RMKFjbKEYap'
        }
    },
    prod: {
        firebaseConfig: {
            apiKey: "AIzaSyBY_77Tn2OyQoA-hjguI-Neff-3sdPjwAI",
            authDomain: "nmcp-80894.firebaseapp.com",
            projectId: "nmcp-80894",
            storageBucket: "nmcp-80894.appspot.com",
            messagingSenderId: "589993158331",
            appId: "1:589993158331:web:71ecd4c1e10de9f0166b9e",
            measurementId: "G-GXY588RJTX"
        },
        CLOUD_FUNCTIONS_LOCATION: 'europe-west3',
        STRIPE_PUBLISHABLE_KEY: 'pk_live_51IpiQ4EI3HJ29RMKt5t71UNkAKQbNVyWbBO9PEws2tcrwcKgNGaM36Usjj0GfSNwvziprFCLxpH1UWwyMoxVdsfb00ZVkRxprr',
        MAPS_API_KEY: 'AIzaSyALZFoO55632x_YZQWF7YixwuhRzCv9KaU',
        product : {
            prices: [
                
                {
                    id: 'price_1NqzukEI3HJ29RMKZSrxJ2IY',
                    description: 'Ανά Μήνα',
                    first_unit_price: 12.09,
                    extra_unit_price: 4.03,
                    vat_percentage: 0.24
                }, {
                    id: 'price_1NqzaUEI3HJ29RMKKIKihGH2',
                    description: 'Ανά Τρίμηνο',
                    first_unit_price: 32.25,
                    extra_unit_price: 8.06,
                    vat_percentage: 0.24
                }, {
                    id: 'price_1NqzoqEI3HJ29RMKrj7DjBsO',
                    description: 'Ανά Έτος',
                    first_unit_price: 80.64,
                    extra_unit_price: 16.12,
                    vat_percentage: 0.24
                }
            ],
            tax_id: 'txr_1IxbGiEI3HJ29RMKY37u38iw'
        }
    }
};

module.exports = {config};
  