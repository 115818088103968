import {useAuth} from "reactfire";
import * as yup from "yup";
import React from "react";
import Menu from "../components/pages/Fragments/Menu";
import Footer from "../components/pages/Fragments/Footer";
import '../css/header.css'
import '../css/footer.css'
import '../css/SignIn.css'
import { useHistory } from "react-router-dom";
import {Button, Nav, Form} from 'react-bootstrap';
import { Formik } from "formik";

function SignIn(props) {

    const auth = useAuth();
    const history = useHistory();
    const schema = yup.object().shape({
        email: yup.string().email().required(),
        password: yup.string().required()
    });

    const signIn = async (values, { setSubmitting, setStatus }) => {
        setStatus();
        try {
            const email = values.email;
            const password = values.password;
            await auth.signInWithEmailAndPassword(email,password);
            history.push("/#");

        } catch (e) {
            setSubmitting(false);
            setStatus({
                message: e.message,
                type: 'error',
            });
        }
    }
    
    return (
        <Formik
            validationSchema={schema}
            validateOnChange={false}
            onSubmit={signIn}
            initialValues={{
                email: '',
                password: ''
            }}
            >
            {({
                  handleSubmit, handleChange,
                  values, errors, status
            }) => (
            <div className="login">
            <Menu/>
            <div className="login-content" >
                <div className="frame">
                    <div className="login-form">
                            <Form className="form" onSubmit={handleSubmit}>   
                            <div className="text-wrapper">Καλως ήρθατε</div>     
                            <Form.Group controlId="signInEmail">
                                <Form.Group >
                                <Form.Label className="label">Διεύθυνση ηλ. ταχυδρομείου</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    isInvalid={!!errors.email}
                                    placeholder="Email"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="signInPassword">
                                <Form.Label className="label">Κωδικός πρόσβασης</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    isInvalid={!!errors.password}
                                    placeholder="Password"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.password}
                                </Form.Control.Feedback>
                            </Form.Group>
                                <Button 
                                    variant="link" 
                                    className="div" 
                                    href="/Forgot">
                                    Ξεχασατε τον κωδικό πρόσβασης;
                                </Button>
                                {status && status.message && (
                                    <div className="error-text">{status.message}</div>
                                )}
                                <Button className="main-button-instance"  onClick={handleSubmit}>
                                    <div className="ButtonText"
                                        variant="primary"
                                        type="submit">Είσοδος</div>
                                </Button>
                                
                                <p className="p">
                                    <span className="design-component-instance-node">Δεν έχετε λογαριασμό;</span>
                                    <Nav>
                                        <Nav.Link className="div2" href="/register">Εγγραφείτε</Nav.Link>
                                    </Nav>
                                </p>
                            </Form.Group>
                            </Form>
                    </div>
                    <div className="element-wrapper">
                        <img className="element" alt="Element" src="./assets/images/Signin_illu.png" />
                    </div>    
                </div>
            </div>
            <Footer/>
        </div>)}
        </Formik>
      );
    };


export default SignIn;