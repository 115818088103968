import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Button, Card} from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import 'firebase/auth';
import 'firebase/firestore'
import {useFirestore, useUser, useSigninCheck} from 'reactfire';
import {useStripe} from '@stripe/react-stripe-js';
import Progress from 'react-progress-2';
import {config} from "../../../config";

function Product({price, setShowModal}) {

  const history = useHistory();
  const {data: signInCheckResult} = useSigninCheck();
  const {data: user} = useUser();
  const firestore = useFirestore();
  const stripe = useStripe();
  const [quantity, setQuantity] = useState(1);

  async function redirectToStripeCheckout() {

    if (signInCheckResult.signedIn === false) {
      window.scrollTo(0, 0);
      return history.push('/login');
    }

    if (!user.emailVerified) {
      setShowModal(true);
      return;
    }

    Progress.show();

    const selectedPrice = {
      price: price.id,
      dynamic_tax_rates: [config[process.env.REACT_APP_ENV].product.tax_id],
      quantity: quantity,
      adjustable_quantity: {
        enabled: true,
        minimum: "1",
        maximum: "99"
      }
    };

    const docRef = await firestore
      .collection('customers')
      .doc(user.uid)
      .collection('checkout_sessions')
      .add({
        allow_promotion_codes: true,
        line_items: [selectedPrice],
        success_url: window.location.origin + '/order/success?session_id={CHECKOUT_SESSION_ID}',
        cancel_url: window.location.origin
      });

    docRef.onSnapshot((snap) => {
      const {error, sessionId} = snap.data();
      if (error) {
        Progress.hide();
        alert(`An error occurred: ${error.message}`);
      }
      if (sessionId) {
        Progress.hide();
        stripe.redirectToCheckout({sessionId});
      }
    });
  }

  function calculatePrice() {
    let total_cost;
    if (quantity == 1) {
      total_cost = price.first_unit_price;
    } else if (quantity > 1) {
      total_cost = (price.first_unit_price + ((quantity - 1) * price.extra_unit_price));
    }
    return (total_cost * (1 + price.vat_percentage)).toFixed(2);
  }


  return (
    <>
      <Card style={{textAlign: "center", borderRadius: "20px"}}>
        <Card.Header style={{borderRadius: "20px 20px 0px 0px"}} as="h5" >{price.description}</Card.Header>
        <Card.Body>
          <Card.Text as="h4">Συνολική τιμή:</Card.Text>
          <div className="box">
              <img className="coin" alt="Coin" src="./assets/images/coin.png" />
              <div className="label">
                  {`${calculatePrice()}`} €
              </div>
          </div>
          <div className="box">
              <img className="line" alt="Line" src="./assets/images/line.png" />
          </div>
          <ul className="list-unstyled mt-3 mb-4">
            <li>{`Για την 1η άδεια: ${(price.first_unit_price * (1 + price.vat_percentage)).toFixed(2)} €`}</li>
            <li>{`Για την 2η άδεια και έπειτα: ${(price.extra_unit_price * (1 + price.vat_percentage)).toFixed(2)} € / άδεια`}</li>
          </ul>
          <Card.Text as="h5">{`${quantity} ${(quantity == 1) ? 'άδεια' : 'άδειες'}`}</Card.Text>
          <RangeSlider
            value={quantity}
            min={1}
            max={99}
            onChange={e => setQuantity(e.target.value)}
          />
          <Button
            className="PriceButton"
            variant="primary"
            size="lg"
            onClick={() => redirectToStripeCheckout()}>
            Αγορά
          </Button>
        </Card.Body>
      </Card>
    </>
  );
}

export default Product