import {Container} from "react-bootstrap";
import Menu from "../components/pages/Fragments/Menu";
import Footer from "../components/pages/Fragments/Footer";
import '../css/TOSPage.css'

function TOS() {
    return (
        <>
        <div className="Tos-section">
            <Menu/>
            <Container className="section-container">
                <h1 className="section-container-title">Όροι Χρήσης</h1>
                <p className="section-container-spacer">
                    Η <a href="https://www.nmcp.gr">www.nmcp.gr</a> είναι ιστοσελίδα, που δημιούργησε η NMCP
                    ΣΥΜΒΟΥΛΕΥΤΙΚΗ ΤΕΧΝΙΚΗ ΤΟΥΡΙΣΤΙΚΗ Μ. ΙΚΕ, η οποία εδρεύει στo
                    Μοσχάτο, Χρυσοστόμου Σμύρνης 16 και εκπροσωπείται νόμιμα, με ΑΡ.ΓΕΜΗ 148552807000, με Α.Φ.Μ.
                    801077482 και Δ.Ο.Υ. ΜΟΣΧΑΤΟΥ, τηλ. 213-0247596 και ηλεκτρονική διεύθυνση επικοινωνίας <a href="mailto:info@nmcp.gr">info@nmcp.gr</a>.
                </p>
                <p>
                    Οι κάτωθι όροι και προϋποθέσεις θα εφαρμόζονται για τη χρήση της ιστοσελίδας της εταιρείας NMCP, η
                    οποία βρίσκεται στην ηλεκτρονική διεύθυνση <a href="https://www.nmcp.gr">www.nmcp.gr</a>.
                    Η χρήση του site προϋποθέτει την ανεπιφύλακτη αποδοχή των παρακάτω όρων, χωρίς καμία εξαίρεση,
                    τους οποίους θα πρέπει να διαβάσετε προσεκτικά. Απαγορεύεται ρητά η χρήση των ιστοσελίδων της
                    NMCP σε περίπτωση μη αποδοχής των παρόντων όρων χρήσης. Η χρήση των ως άνω ιστοσελίδων υποδηλώνει
                    την πλήρη αποδοχή των όρων και προϋποθέσεων που έχει θέσει η NMCP.
                </p>
                <p>
                    Οι επισκέπτες του διαδικτυακού τόπου συμφωνούν ότι:
                </p>
                <ul>
                    <li>
                        Θα χρησιμοποιούν τα πραγματικά τους στοιχεία (επώνυμο, όνομα, διεύθυνση, e-mail) στις φόρμες
                        επικοινωνίας.
                    </li>
                    <li>
                        Θα χρησιμοποιούν το site σύμφωνα με τον νόμο και τα χρηστά ήθη και δε θα προβαίνουν σε ενέργειες
                        ή παραλείψεις που μπορεί να προκαλέσουν βλάβη σε αυτό αλλά και σε οποιονδήποτε άλλο χρήστη.
                    </li>
                    <li>
                        Ειδικότερα απαγορεύεται η χρήση των υπηρεσιών της Εταιρείας για οποιοδήποτε μη νόμιμο σκοπό
                        περιλαμβανομένων αλλά μη περιοριζόμενων σε δημοσίευση πορνογραφικού περιεχομένου, πειρατικού
                        περιεχομένου, αρχείων προερχόμενων από κλοπή, αναδημοσίευση περιεχομένου χωρίς την απαραίτητη
                        άδεια του εκδότη και την παραχώρηση πνευματικών δικαιωμάτων, αποστολή μαζικής αλληλογραφίας
                        χωρίς την έγκριση των παραληπτών και την αναφορά της ταυτότητας του αποστολέα.
                        Κάθε χρήστης που χρησιμοποιεί ή προτρέπει τους επισκέπτες των σελίδων του μέσω links σε σελίδες
                        με περιεχόμενο ή πρακτικές που εμπίπτουν στα παραπάνω, μπορεί να οδηγηθεί σε άμεση διακοπή της
                        συνεργασίας με την NMCP., η οποία διατηρεί το δικαίωμα να διεκδικήσει αποζημίωση από τη ζημία
                        θετική και αποθετική που ήθελε υποστεί εκ της μη σύννομης κατά τα ανωτέρω συμπεριφοράς.
                    </li>
                </ul>
                <p>
                    Η NMCP διατηρεί το δικαίωμα να τροποποιεί ή να αναθεωρεί ελεύθερα τούς όρους και τις προϋποθέσεις
                    του site της, οποτεδήποτε το κρίνει αναγκαίο, αναλαμβάνει δε την υποχρέωση να ενημερώνει τους
                    επισκέπτες για οποιανδήποτε μεταβολή, μέσα από τις σελίδες του παρόντος διαδικτυακού τόπου.
                </p>
                <p>
                    Τα πνευματικά δικαιώματα του περιεχομένου (συμπεριλαμβανομένων κειμένων, γραφικών, εικόνων,
                    φωτογραφιών και λογισμικού) και των υπηρεσιών του site που έχουν εισαχθεί ή θα εισαχθούν στο
                    διαδίκτυο, προστατεύονται από τους ελληνικούς, κοινοτικούς και διεθνείς νόμους περί πνευματικής
                    ιδιοκτησίας και ανήκουν αποκλειστικά στην NMCP. Απαγορεύεται αυστηρά η οποιαδήποτε αντιγραφή,
                    αναπαραγωγή και αναμετάδοση του υλικού, χωρίς έγγραφη άδεια της εταιρείας, διατίθενται δε στους
                    επισκέπτες του site μόνο για προσωπική χρήση (μη κερδοσκοπική ή εμπορική).
                </p>
                <p>
                    Τα ονόματα, εικόνες, λογότυπα και διακριτικά γνωρίσματα που αντιπροσωπεύουν το site με το εμπορικό
                    σήμα NMCP ή τρίτα μέρη και τα προϊόντα ή τις υπηρεσίες τους, είναι αποκλειστικά σήματα της NMCP ή
                    τρίτων μερών προστατευόμενα από τους σχετικούς νόμους περί εμπορικών σημάτων. Η εμφάνισή τους στο
                    διαδικτυακό τόπο δε θα πρέπει κατά κανένα τρόπο να εκληφθεί ως μεταβίβαση ή εκχώρηση άδειας ή
                    δικαιώματος χρήσης τους.
                </p>
                <p>
                    Η NMCP δηλώνει ότι το περιεχόμενο, τα προϊόντα και οι υπηρεσίες παρέχονται μέσω του site “ΩΣ ΈΧΟΥΝ”
                    Η NMCP καταβάλει κάθε δυνατή προσπάθεια να παρέχει υπηρεσίες υψηλής ποιότητας, αλλά δεν εγγυάται ότι
                    δεν θα υπάρξουν διακοπές, λάθη ή βλαπτικά στοιχεία στα περιεχόμενα του διαδικτυακού τόπου και
                    συνεπώς δεν ευθύνεται για οποιαδήποτε βλάβη προκληθεί σε περίπτωση μη διαθεσιμότητας του δικτύου ή
                    του συστήματος. Κατά συνέπεια, η Εταιρεία δεν ευθύνεται για τυχόν προβλήματα που παρουσιάζονται στο
                    ανέβασμα του περιεχομένου της ιστοσελίδας στο server του χρήστη.
                </p>
                <p>
                    Η Εταιρεία δεν ευθύνεται για το περιεχόμενο των πληροφοριών που περνάνε μέσα από το δίκτυο των
                    server της. Δεν εγγυάται την αξιοπιστία οποιασδήποτε πληροφορίας που εμφανίζεται στο διαδίκτυο μέσω
                    ή εξαιτίας των υπηρεσιών της ή την εμπορική ή προσωπική φερεγγυότητα οποιουδήποτε παρουσιάζεται στο
                    διαδίκτυο και δεν είναι υπεύθυνη για οποιεσδήποτε ζημιές μπορούν να συμβούν στο χρήστη, επισκέπτη
                    ή σε αυτούς που συναλλάσσονται με αυτόν, συμπεριλαμβανομένης και της απώλειας δεδομένων εξαιτίας
                    καθυστερήσεων, μη παράδοσης εμπορευμάτων ή διακοπής υπηρεσιών για οποιαδήποτε αιτία, λάθος ή
                    παράλειψη ούτε και φέρει καμία ευθύνη για τυχόν ζημία ούτε για τυχόν απαιτήσεις νομικής ή αστικής ή
                    ποινικής φύσης από χρήστες του site. Οι επισκέπτες συμφωνούν στη χρήση αυτού με δική τους ευθύνη.
                </p>
                <p><b>Δήλωση Εχεμύθειας</b></p>
                <p>
                    Οι ερωτήσεις, οι αιτήσεις που συμπληρώνονται και γενικά η ηλεκτρονική αλληλογραφία θεωρούνται
                    απόρρητες.
                </p>
                <p>
                    Οι πληροφορίες που μας παρέχονται χρησιμοποιούνται μόνο για την αποστολή ηλεκτρονικής απάντησης και
                    δε μεταβιβάζονται παρά μόνο στον ενδιαφερόμενο αποδέκτη. Επίσης σε περίπτωση που μας ζητηθεί από τον
                    νόμο ή αν κρίνουμε ότι το περιεχόμενο των μηνυμάτων μας βλάπτει, θα μεταβιβάζονται και στις αρμόδιες
                    Αρχές προκειμένου να:
                </p>
                <ul>
                    <li>
                        προστατευθούμε ενάντια στη μη εξουσιοδοτημένη, στη μη σύμφωνη με τον νόμο και γενικά κακή χρήση
                        του site
                    </li>
                    <li>
                        προστατεύσουμε την ασφάλεια και την περιουσία των χρηστών
                    </li>
                    <li>
                        προστατεύσουμε τα νόμιμα δικαιώματά μας και τα περιουσιακά μας στοιχεία.
                    </li>
                </ul>
                <p>
                    Η NMCP έχει το δικαίωμα να προσθέτει και να μεταβάλλει το περιεχόμενο ή τις υπηρεσίες του site,
                    καθώς και τους όρους χρήσης, όποτε κρίνει ότι είναι απαραίτητο και χωρίς προηγούμενη προειδοποίηση,
                    με μόνη την ανακοίνωσή τους μέσω του site της.
                </p>
                <p>
                    Οποιαδήποτε αναφορά ή διασύνδεση (link) με άλλο site, παρέχεται για τη διευκόλυνσή των χρηστών μας
                    και δεν είμαστε υπεύθυνοι για τα περιεχόμενα, τα προϊόντα και τις υπηρεσίες που παρέχονται στα site
                    αυτά ή οποιουδήποτε συνδέσμου που περιλαμβάνεται σε μία συσχετιζόμενη ιστοσελίδα, ή οποιωνδήποτε
                    αλλαγών ή ενημερώσεων σε τέτοιες ιστοσελίδες
                </p>
                <p>
                    Οι παρόντες όροι χρήσης των ως άνω διαδικτυακών τόπων συντάχθηκαν με βάση το σύνολο των κανόνων
                    δικαίου τόσο τους ελληνικούς όσο και τους ευρωπαϊκούς και διεθνείς και ερμηνεύονται σύμφωνα με τους
                    κανόνες της καλής πίστης, των συναλλακτικών ηθών και του κοινωνικού και οικονομικού σκοπού του
                    δικαιώματος. Σε περίπτωση κατά την οποία όρος ή πρόβλεψη των παρόντων όρων χρήσης ήθελε κριθεί
                    άκυρος ή ακυρώσιμος, η ακυρότητα/ ακυρωσία αυτή δεν επηρεάζει την ισχύ των λοιπών όρων.
                </p>
            </Container>
            </div>
            <Footer/>
        </>

    );
}

export default TOS;
