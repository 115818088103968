import { Switch, Route } from 'react-router-dom';
import Main from './pages/Main';
import Signin from './pages/SignIn';
import NotFound from "./pages/NotFound";
import ForgotPassword from "./pages/ForgotPassword";
import Register from "./pages/Register";
import AccountSettings from "./pages/AccountSettings";
import TOS from "./pages/TOS";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PrivateRoute from "./components/PrivateRoute";

function App() {
  return (
    <Switch>
      <Route exact path="/" component={Main}/>
      <Route exact path="/tos" component={TOS}/>
      <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
      <Route exact path="/login" component={Signin}/>
      <Route exact path="/Forgot" component={ForgotPassword}/>
      <Route exact path="/Register" component={Register}/>
      
      <PrivateRoute exact path="/account">
            <AccountSettings/>
      </PrivateRoute>
      <Route component={NotFound} />
    </Switch>
  );
}

export default App;
