import Menu from "../components/pages/Fragments/Menu";
import {Container} from "react-bootstrap";
import Footer from "../components/pages/Fragments/Footer";
import '../css/PrivacyPolicy.css'

function PrivacyPolicy() {
    return (
        <>
        <div className="Privacy-section">
            <Menu/>
            <Container className="section-container">
                <h1 className="section-container-title">Πολιτική Απορρήτου</h1>
                <p className="section-container-spacer">
                    Ισχύει απο 18/05/2021
                </p>
                <p>
                    Ενημέρωση για την Επεξεργασία Προσωπικών Δεδομένων
                </p>
                <p>
                    Θα θέλαμε να σας ενημερώσουμε ότι για την NMCP η προστασία των προσωπικών δεδομένων έχει πρωταρχική
                    σημασία. Για το λόγο αυτό λαμβάνουμε τα κατάλληλα τεχνικά και οργανωτικά μέτρα για να προστατέψουμε
                    τα προσωπικά δεδομένα που επεξεργαζόμαστε και να διασφαλίσουμε ότι η επεξεργασία τους,
                    πραγματοποιείται πάντοτε σύμφωνα με τις υποχρεώσεις που τίθενται από το νομικό πλαίσιο, τόσο από την
                    ίδια την εταιρεία, όσο και από τρίτους που επεξεργάζονται προσωπικά δεδομένα για λογαριασμό της
                    εταιρείας.
                </p>
                <p>
                    Η παρούσα Πολιτική Απορρήτου και Προστασίας Προσωπικών Δεδομένων ισχύει για τις υπηρεσίες που
                    παρέχουμε προς τους πελάτες μας, για τις επικοινωνίες και προωθητικές ενέργειες για κάθε
                    ενδιαφερόμενο και για τον διαδικτυακό τόπο www.nmcp.gr και τις ηλεκτρονικές υπηρεσίες του.
                </p>
                <p><b>Τι είναι ο GDPR</b></p>
                <p>
                    Ο Γενικός Κανονισμός για την Προστασία των Προσωπικών Δεδομένων (General Data Protection Regulation
                    – GDPR) 2016/679 αποτελεί το νέο κανονιστικό πλαίσιο της Ευρωπαϊκής Ένωσης (ΕΕ) στον εξεταζόμενο
                    τομέα. Αντικείμενο του νόμου είναι η θέσπιση των προϋποθέσεων για την επεξεργασία δεδομένων
                    προσωπικού χαρακτήρα, την προστασία των δικαιωμάτων και των ελευθεριών των φυσικών προσώπων και
                    ιδίως του δικαιώματος προστασίας προσωπικών δεδομένων.
                </p>
                <p><b>Υπεύθυνος Επεξεργασίας ή Εκτελών την Επεξεργασία</b></p>
                <p>
                    Η εταιρεία “NMCP ΣΥΜΒΟΥΛΕΥΤΙΚΗ ΤΕΧΝΙΚΗ ΤΟΥΡΙΣΤΙΚΗ Μ. ΙΚΕ” με την επωνυμία «NMCP», η οποία εδρεύει
                    στo Μοσχάτο, Χρυσοστόμου Σμύρνης 16 και εκπροσωπείται νόμιμα, με ΑΡ.ΓΕΜΗ 148552807000, με Α.Φ.Μ.
                    801077482 και Δ.Ο.Υ. ΜΟΣΧΑΤΟΥ, τηλ. 213-0247596 και ηλεκτρονική διεύθυνση επικοινωνίας info@nmcp.gr
                    όπως νόμιμα εκπροσωπείται, ενημερώνει ότι, για σκοπούς άσκησης των επιχειρηματικών δραστηριοτήτων
                    της, προβαίνει σε επεξεργασία προσωπικών δεδομένων των πελατών της σύμφωνα με την ισχύουσα εθνική
                    νομοθεσία και τον Ευρωπαϊκό Κανονισμό 2016/679 για την προστασία των φυσικών προσώπων έναντι της
                    επεξεργασίας των δεδομένων προσωπικού χαρακτήρα και για την ελεύθερη κυκλοφορία των δεδομένων αυτών
                    (Γενικός Κανονισμός για την Προστασία Δεδομένων, εφεξής «Κανονισμός») όπως ισχύει. Η εταιρεία NMCP,
                    ανάλογα με τα ιδιαίτερα χαρακτηριστικά της εκάστοτε σύμβασης που υπογράφει με τους πελάτες της,
                    ενδέχεται να ενεργεί άλλοτε ως υπεύθυνος επεξεργασίας και άλλοτε ως εκτελών την επεξεργασία.
                </p>
                <p>
                    Όταν η εταιρεία NMCP καθορίζει η ίδια το σκοπό και τον τρόπο της επεξεργασίας των προσωπικών
                    δεδομένων που διαχειρίζεται (πχ στις σχέσεις με τους εργαζομένους της, στον τρόπο επεξεργασίας των
                    στοιχείων επικοινωνίας των πελατών του) τότε ενεργεί ως υπεύθυνος επεξεργασίας, σύμφωνα με το άρθρο
                    4 περ. 7 του GDPR.
                </p>
                <p>
                    Όταν η εταιρεία NMCP λειτουργεί ως πάροχος υπηρεσιών προς τον πελάτη προσφέροντας υπηρεσίες
                    υλοποίησης και υποστήριξης συστημάτων πληροφορικής και έτσι επεξεργάζεται προσωπικά δεδομένα για
                    λογαριασμό του πελάτη της, τότε θεωρείται ως εκτελών την επεξεργασία σύμφωνα με το άρθρο 4 περ. 8
                    του GDPR, ενώ ο πελάτης της θεωρείται υπεύθυνος της επεξεργασίας των προσωπικών δεδομένων. Η
                    εταιρεία NMCP, όταν ενεργεί ως εκτελών την επεξεργασία δεσμεύεται απέναντι στον πελάτη της-υπεύθυνο
                    επεξεργασίας, να λάβει τα απαιτούμενα τεχνικά και οργανωτικά μέτρα, ώστε να είναι σε θέση να
                    εγγυηθεί την προστασία των προσωπικών δεδομένων που επεξεργάζεται, σύμφωνα με τις επιταγές του GDPR.
                </p>
                <p>
                    Για οποιοδήποτε θέμα σχετικά με την επεξεργασία προσωπικών δεδομένων μπορείτε να απευθυνθείτε στον
                    Τμήμα Προστασίας Προσωπικών Δεδομένων και τον Υπεύθυνο Προστασίας Δεδομένων (DPO) της NMCP, στα
                    ακόλουθα στοιχεία επικοινωνίας:
                </p>
                <p>email: info@nmcp.gr</p>
                <p>Τηλέφωνο: +302130247596</p>
                <p><b>Ποιες κατηγορίες προσωπικών δεδομένων επεξεργαζόμαστε και για ποιο σκοπό</b></p>
                <p>Τα προσωπικά δεδομένα σας τα επεξεργαζόμαστε μόνο για νόμιμο σκοπό.</p>
                <p><b>Προσωπικά δεδομένα που μας παρέχετε για τα προϊόντα και την υλοποίηση υπηρεσιών</b></p>
                <p>
                    Demo ή Παρουσίαση: υπηρεσία κατά την οποία μας παρέχετε τη δυνατότητα να επικοινωνήσουμε μαζί σας,
                    για να οργανώσουμε μία παρουσίαση των επιχειρησιακών λύσεών μας (ως υπεύθυνος της επεξεργασίας ή ως
                    εκτελών την επεξεργασία). Τα προσωπικά στοιχεία που καταχωρούνται στα πληροφοριακά μας συστήματα για
                    τον παραπάνω σκοπό είναι τα: ονοματεπώνυμο, email, κινητό/σταθερό τηλέφωνο.
                </p>
                <p>
                    Νομική βάση για την ανωτέρω επεξεργασία είναι η εκτέλεση των συμβατικών μας υποχρεώσεων (ΓΚΠΔ άρθρο
                    6 παρ.1΄β), στην περίπτωση που υφίσταται μεταξύ μας σύμβαση και η ανωτέρω υπηρεσία παρέχεται στο
                    πλαίσιο αυτής ή στην περίπτωση που βρισκόμαστε σε διαπραγματεύσεις για την σύναψη σύμβασης μεταξύ
                    μας ή η ρητή και ελεύθερη συναίνεσή σας (ΓΚΠΔ άρθρο 6 παρ.1ά).
                </p>
                <p><b>Προσωπικά δεδομένα για τις εμπορικές συναλλαγές</b></p>
                <p>
                    Διατηρούμε στα Πληροφοριακά μας συστήματα και στις συμβάσεις με τους πελάτες και προμηθευτές μας,
                    προσωπικά δεδομένα για τις εμπορικές συναλλαγές (πωλήσεις, αγορές, πληρωμές κλπ), στοιχεία επαφών
                    για τις εν λόγω διαδικασίες (ως υπεύθυνος της επεξεργασίας).
                </p>
                <p>
                    Η νομική βάση για τη συγκεκριμένη επεξεργασία, είναι η συμμόρφωση της εταιρείας με έννομη υποχρέωση
                    (ΓΚΠΔ άρθρο 6 παρ.1΄γ) και συγκεκριμένα με φορολογική υποχρέωση.
                </p>
                <p><b>Παιδιά</b></p>
                <p>
                    Με την παροχή της συγκατάθεσής σας δηλώνετε υπεύθυνα πως είστε άνω των 16 ετών. Εάν είστε κάτω των
                    16 ετών, μπορείτε να χρησιμοποιήσετε την ιστοσελίδα μας και τις υπηρεσίες της μόνο με τη συμμετοχή
                    και την έγκριση ενός γονέα ή κηδεμόνα.
                </p>
                <p><b>Πώς και γιατί χρησιμοποιούμε τα προσωπικά δεδομένα σας</b></p>
                <p>
                    Ενδέχεται να χρησιμοποιήσουμε τις πληροφορίες που συλλέγουμε για τους παρακάτω σκοπούς:
                </p>
                <ul>
                    <li>
                        Eγγραφή σας στον παρόντα διαδικτυακό τόπο ή σε μια υπηρεσία του.
                    </li>
                    <li>
                        Aποστολή των συνθηματικών (username, password) για demo παρουσιάσεις.
                    </li>
                    <li>
                        Αποστολή ενημερωτικών δελτίων ή τηλεφωνική ενημέρωση για προϊόντα-υπηρεσίες, δράσεις και νέα της
                        NMCP.
                    </li>
                    <li>
                        Υλοποίηση σύμβασης υπηρεσιών NMCP.
                    </li>
                </ul>
                <p><b>Πού κοινοποιούνται τα προσωπικά σας δεδομένα</b></p>
                <p>
                    Η NMCP ενδέχεται να διαβιβάζει τα προσωπικά δεδομένα που παρέχονται από τα φυσικά πρόσωπα σε τρίτους
                    στις παρακάτω περιπτώσεις και για συγκεκριμένους σκοπούς.
                </p>
                <ul>
                    <li>
                        Πιστοποιημένοι συνεργάτες της NMCP (Εκτελούντες την επεξεργασία). Πρόκειται για εταιρείες με
                        πιστοποιημένους συμβούλους υλοποίησης και υποστήριξης στα προϊόντα και υπηρεσίες της NMCP, οι
                        οποίοι μπορεί να χρησιμοποιήσουν τις απαραίτητες πληροφορίες για να παρέχουν τις εν λόγω
                        υπηρεσίες. Υπάρχει πάντα συμβατική σχέση μεταξύ της NMCP και του Πιστοποιημένου Συνεργάτη με τις
                        απαραίτητες δεσμεύσεις εμπιστευτικότητας και λήψης των κατάλληλων οργανωτικών και τεχνικών
                        μέτρων για την προστασία των προσωπικών δεδομένων.
                    </li>
                    <li>
                        Άλλα τρίτα μέρη, λόγω νομοθεσίας: Ενδέχεται να κοινοποιήσουμε τα απαραίτητα προσωπικά δεδομένα
                        σας σε τρίτα πρόσωπα, όπως ιδίως αστυνομικές, δικαστικές ή εισαγγελικές αρχές, φορολογικές αρχές
                        και ασφαλιστικοί φορείς, για να συμμορφωθούμε με τη νομοθεσία ή για να ανταποκριθούμε σε μια
                        υποχρεωτική νομική διαδικασία, για να προστατεύσουμε τα δικαιώματα ή την ασφάλεια της εταιρείας
                        NMCP.
                    </li>
                    <li>
                        Άλλα τρίτα μέρη, για την υλοποίηση των υπηρεσιών NMCP: Υπάρχουν περιπτώσεις που θα πρέπει να
                        κοινοποιήσουμε τα απαραίτητα προσωπικά δεδομένα για την απρόσκοπτη λειτουργία κάποιων
                        ηλεκτρονικών υπηρεσιών (datacenter, hosting, κλπ).
                    </li>
                    <li>
                        Άλλα τρίτα μέρη με τη συγκατάθεσή σας. Εκτός από τις κοινοποιήσεις που περιγράφονται στην
                        παρούσα Πολιτική Απορρήτου και Προστασίας Προσωπικών Δεδομένων, ενδέχεται να χρησιμοποιήσουμε
                        από κοινού πληροφορίες σχετικά με σας με τρίτα μέρη, εφόσον δώσετε την ελεύθερη και ρητή
                        συγκατάθεσή σας .
                    </li>
                    <li>
                        Στην Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα, σε περίπτωση που ανακύψει περιστατικό
                        παραβίασης των προσωπικών σας δεδομένων.
                    </li>
                </ul>
                <p><b>Χρονικό Διάστημα Αποθήκευσης</b></p>
                <p>
                    Το χρονικό διάστημα αποθήκευσης των δεδομένων αποφασίζεται με βάση τα παρακάτω ειδικότερα κριτήρια
                    ανάλογα με την περίπτωση:
                </p>
                <ul>
                    <li>
                        Όταν η επεξεργασία επιβάλλεται ως υποχρέωση από διατάξεις του ισχύοντος νομικού πλαισίου, τα
                        προσωπικά σας δεδομένα θα αποθηκεύονται για όσο χρονικό διάστημα επιβάλλουν οι σχετικές
                        διατάξεις.
                    </li>
                    <li>
                        Όταν η επεξεργασία εκτελείται με βάση σύμβαση, τα προσωπικά σας δεδομένα αποθηκεύονται για όσο
                        χρονικό διάστημα είναι απαραίτητο για την εκτέλεση της σύμβασης και για τη θεμελίωση, άσκηση,
                        ή/και υποστήριξη νομικών αξιώσεων με βάση τη σύμβαση.
                    </li>
                    <li>
                        Όταν η πραγματοποιούμενη επεξεργασία στηρίζεται στη συγκατάθεσή σας, τα προσωπικά σας δεδομένα
                        τηρούνται μέχρι την ανάκληση αυτής. Αυτό μπορεί να πραγματοποιηθεί από εσάς οποιαδήποτε στιγμή.
                        Η ανάκληση της συγκατάθεσης δεν θίγει τη νομιμότητα της επεξεργασίας που βασίστηκε στη
                        συγκατάθεση κατά το χρονικό διάστημα πριν την ανάκλησή της.
                    </li>
                </ul>
                <p><b>Ασφάλεια Προσωπικών Δεδομένων</b></p>
                <p>
                    Η NMCP εφαρμόζει κατάλληλα τεχνικά και οργανωτικά μέτρα με στόχο την ασφαλή επεξεργασία των
                    προσωπικών δεδομένων και την αποτροπή τυχαίας απώλειας ή καταστροφής και μη εξουσιοδοτημένης ή/και
                    παράνομης πρόσβασης σε αυτά, χρήσης, τροποποίησης ή αποκάλυψής τους. Σε κάθε περίπτωση, ο τρόπος
                    λειτουργίας του διαδικτύου και το γεγονός ότι είναι ελεύθερο στον οποιονδήποτε, δεν επιτρέπει να
                    παρασχεθούν εγγυήσεις ότι μη εξουσιοδοτημένα τρίτα πρόσωπα δεν θα αποκτήσουν ποτέ τη δυνατότητα να
                    παραβιάσουν τα εφαρμοζόμενα τεχνικά και οργανωτικά μέτρα, αποκτώντας πρόσβαση και προβαίνοντας
                    ενδεχομένως σε χρήση προσωπικών δεδομένων για μη εξουσιοδοτημένους ή/και αθέμιτους σκοπούς.
                </p>
                <p>
                    Στις περιπτώσεις όπου, είτε λόγω χρήσης νέων τεχνολογιών, είτε λόγω μεγάλης κλίμακας επεξεργασίας
                    των ειδικών κατηγοριών δεδομένων ενδέχεται να επιφέρει υψηλό κίνδυνο για τα δικαιώματα και τις
                    ελευθερίες των πελατών μας, η NMCP πριν διενεργήσει την εν λόγω επεξεργασία, προβαίνει σε εκτίμηση
                    των επιπτώσεων των σχεδιαζόμενων πράξεων επεξεργασίας στην προστασία δεδομένων προσωπικού χαρακτήρα
                    (DPIA).
                </p>
                <p><b>Ενέργειες σε περίπτωση παραβίασης των προσωπικών σας δεδομένων</b></p>
                <p>
                    Σε περίπτωση που διαπιστωθεί παραβίαση των προσωπικών σας δεδομένων και η παραβίαση αυτή ενδέχεται
                    να προκαλέσει κίνδυνο για τα δικαιώματα και τις ελευθερίες σας, η NMCP, όταν ενεργεί ως υπεύθυνος
                    επεξεργασίας, δεσμεύεται να γνωστοποιήσει αμελλητί και, αν είναι δυνατό, εντός 72 ωρών από τη στιγμή
                    που λάβει γνώση του γεγονότος της παραβίασης, στην Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα.
                    Όταν η NMCP ενεργεί ως εκτελών την επεξεργασία, δεσμεύεται να γνωστοποιήσει το περιστατικό της
                    παραβίασης των προσωπικών σας δεδομένων στον υπεύθυνο επεξεργασίας αμελλητί. Εάν μάλιστα πρόκειται
                    για αυξημένη διακινδύνευση των δικαιωμάτων σας, η NMCP θα σπεύσει να σας ενημερώσει σχετικά, εκτός
                    αν χρησιμοποιώντας τα κατάλληλα τεχνικά και οργανωτικά μέσα που διαθέτει, κατορθώσει να προσπελάσει
                    τον κίνδυνο.
                </p>
                <p><b>Ποια είναι τα δικαιώματα σας σε σχέση με τα προσωπικά σας δεδομένα</b></p>
                <p>
                    Κάθε φυσικό πρόσωπο τα δεδομένα του οποίου είναι αντικείμενο επεξεργασίας από την NMCP έχει τα
                    ακόλουθα δικαιώματα:
                </p>
                <p><b>Δικαίωμα ενημέρωσης:</b></p>
                <p>
                    Έχετε δικαίωμα ενημέρωσης σχετικά με την ταυτότητα και τα στοιχεία επικοινωνίας μας, ή των εκπροσώπων μας, τα στοιχεία επικοινωνίας του υπευθύνου προστασίας δεδομένων (DPO), τους σκοπούς της επεξεργασίας για τους οποίους προορίζονται τα δεδομένα προσωπικού χαρακτήρα, καθώς και τη νομική βάση για την επεξεργασία, τους αποδέκτες ή τις κατηγορίες αποδεκτών των δεδομένων προσωπικού χαρακτήρα. Στο πλαίσιο της αρχής της διαφάνειας που διέπει τη λειτουργία της εταιρείας μας, μπορείτε να απευθύνεστε σε εμάς ζητώντας περαιτέρω πληροφορίες για τον τρόπο επεξεργασίας των προσωπικών σας δεδομένων και τον τρόπο άσκησης των δικαιωμάτων σας, υποβάλλοντας τα αντίστοιχα αιτήματα. Η απάντηση των αιτημάτων σας θα γίνεται χωρίς καθυστέρηση και σε κάθε περίπτωση εντός μηνός από την παραλαβή του αιτήματος. Η εν λόγω προθεσμία μπορεί να παραταθεί κατά δύο ακόμη μήνες, εφόσον απαιτείται, λαμβανομένων υπόψη της πολυπλοκότητας του αιτήματος και του αριθμού των αιτημάτων.
                </p>
                <p><b>Δικαίωμα πρόσβασης:</b></p>
                <p>
                    Έχετε δικαίωμα να έχετε επίγνωση και να επαληθεύετε τη νομιμότητα της επεξεργασίας και να μας ζητάτε αντίγραφα των δεδομένων προσωπικού χαρακτήρα που υποβάλλονται σε επεξεργασία. Έτσι λοιπόν, έχετε δικαίωμα να έχετε πρόσβαση στα δεδομένα και να λάβετε συμπληρωματικές πληροφορίες σχετικά με την επεξεργασία τους. Επίσης έχετε δικαίωμα πρόσβασης σε ειδικότερες πληροφορίες σχετικά με το περιεχόμενο και τον τρόπο άσκησης των επιμέρους δικαιωμάτων σας.
                </p>
                <p><b>Δικαίωμα διόρθωσης:</b></p>
                <p>
                    Έχετε δικαίωμα να μελετήσετε, να διορθώσετε, να επικαιροποιήσετε ή να τροποποιήσετε τα προσωπικά σας δεδομένα.
                </p>
                <p><b>Δικαίωμα διαγραφής:</b></p>
                <p>
                    Έχετε δικαίωμα να υποβάλετε αίτημα διαγραφής των προσωπικών σας δεδομένων όταν τα επεξεργαζόμαστε με βάση την συγκατάθεσή σας ή  προκειμένου να προστατεύσουμε τα έννομα συμφέροντα μας. Σε όλες τις υπόλοιπες περιπτώσεις (όπως ενδεικτικά όταν υπάρχει σύμβαση, υποχρέωση επεξεργασίας προσωπικών δεδομένων που επιβάλλεται από το νόμο, δημόσιο συμφέρον), το εν λόγω δικαίωμα υπόκειται σε συγκεκριμένους περιορισμούς ή δεν υφίσταται ανάλογα με την περίπτωση (πχ δικαιούμαστε να αρνηθούμε τη διαγραφή των προσωπικών σας δεδομένων αποσκοπώντας στη θεμελίωση, άσκηση ή υποστήριξη νομικών μας αξιώσεων).
                </p>
                <p><b>Δικαίωμα περιορισμού της επεξεργασίας:</b></p>
                <p>
                    Έχετε δικαίωμα να ζητήσετε περιορισμό της επεξεργασίας των προσωπικών σας δεδομένων στις ακόλουθες περιπτώσεις: (α) όταν αμφισβητείτε την ακρίβεια των προσωπικών δεδομένων και μέχρι να γίνει επαλήθευση, (β) όταν αντιτίθεστε στη διαγραφή προσωπικών δεδομένων και ζητάτε αντί διαγραφής τον περιορισμό χρήσης αυτών, (γ) όταν τα προσωπικά δεδομένα δεν χρειάζονται για τους σκοπούς επεξεργασίας, σας είναι ωστόσο απαραίτητα για τη θεμελίωση, άσκηση, υποστήριξη νομικών αξιώσεων, και (δ) όταν εναντιώνεστε στην επεξεργασία και μέχρι να γίνει επαλήθευση ότι υπάρχουν νόμιμοι λόγοι που μας αφορούν και υπερισχύουν των λόγων για τους οποίους εναντιώνεστε στην επεξεργασία.
                </p>
                <p><b>Δικαίωμα εναντίωσης στην επεξεργασία:</b></p>
                <p>
                    Έχετε δικαίωμα να εναντιωθείτε ανά πάσα στιγμή στην επεξεργασία των προσωπικών σας δεδομένων στις περιπτώσεις που, όπως περιγράφεται παραπάνω, αυτή είναι απαραίτητη για σκοπούς εννόμων συμφερόντων που επιδιώκουμε ως υπεύθυνοι επεξεργασίας, καθώς επίσης και στην επεξεργασία για σκοπούς απευθείας ενημερωτικής προώθησης. Ειδικότερα, έχετε δικαίωμα να εναντιώνεστε σε κάθε απόφαση που λαμβάνεται αποκλειστικά βάσει αυτοματοποιημένης επεξεργασίας, συμπεριλαμβανομένης της κατάρτισης προφίλ, η οποία παράγει έννομα αποτελέσματα που σας αφορούν ή σας επηρεάζει σημαντικά. Κατ’ εξαίρεση δε, μπορείτε να αντιταχθείτε στην αυτοματοποιμένη λήψη απόφασης που σας αφορά, όταν η απόφαση αυτή, είτε είναι αναγκαία για τη σύναψη ή την εκτέλεση της σύμβασης που έχουμε συνάψει μαζί σας, είτε βασίζεται στη ρητή και ελεύθερη συγκατάθεσή σας.
                </p>
                <p><b>Δικαίωμα στη φορητότητα:</b></p>
                <p>
                    Έχετε δικαίωμα να λάβετε χωρίς χρέωση τα προσωπικά σας δεδομένα σε μορφή που θα σας επιτρέπει να έχετε πρόσβαση σε αυτά, να τα χρησιμοποιήσετε και να τα επεξεργαστείτε με τις κοινώς διαδεδομένες μεθόδους επεξεργασίας. Επίσης, έχετε δικαίωμα να μας ζητήσετε, εφόσον είναι τεχνικά εφικτό, να διαβιβάσουμε τα δεδομένα και απευθείας σε άλλον υπεύθυνο επεξεργασίας. Το δικαίωμα σας αυτό υπάρχει για τα δεδομένα που μας έχετε παράσχει εσείς και η επεξεργασία τους διενεργείται με αυτοματοποιημένα μέσα με βάση της συγκατάθεσή σας ή σε εκτέλεση σχετικής σύμβασης.
                </p>
                <p><b>Δικαίωμα ανάκλησης της συγκατάθεσης:</b></p>
                <p>
                    Τέλος, η NMCP σας ενημερώνει ότι όπου η επεξεργασία βασίζεται στη ρητή και ελεύθερη συγκατάθεσή σας, έχετε δικαίωμα να την ανακαλέσετε ελεύθερα, χωρίς να θιγεί η νομιμότητα της επεξεργασίας που βασίστηκε στη συγκατάθεση σας, προτού την ανακαλέσετε.
                </p>
                <p>
                    Για να ανακαλέσετε τη συγκατάθεσή σας μπορείτε να απευθυνθείτε στον Τμήμα Προστασίας Προσωπικών Δεδομένων της NMCP, στα ακόλουθα στοιχεία επικοινωνίας:
                </p>
                <p>
                    email: info@nmcp.gr.
                </p>
                <p><b>Δικαίωμα καταγγελίας στην ΑΠΔΠΧ</b></p>
                <p>
                    Σε περίπτωση παραβίασης των προσωπικών σας δεδομένων έχετε δικαίωμα υποβολής καταγγελίας στην Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα (www.dpa.gr): Τηλεφωνικό Κέντρο: +30 210 6475600,
                </p>
                <p>Fax: +30 210 6475628,</p>
                <p>Ηλεκτρονικό Ταχυδρομείο: contact@dpa.gr.</p>
                <p>Ημερομηνία ενημέρωσης: 18/05/2021</p>
            </Container>
            </div>
            <Footer/>
        </>
    );
}

export default PrivacyPolicy;