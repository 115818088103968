import {Col, Container, Row} from "react-bootstrap";

//src="./assets/images/support_illu.png"
function Footer(){
    return (
      <div className="footer-section" id="contact">
      <footer className="footer">
        <div className="THLEFONO">
          <div className="element">
            <div className="text-wrapper">Τηλ. Επικοινωνίας:</div>
            <div className="icon">
              <img className="footer-phone" alt="Footer phone" src="./assets/images/Footer_phone.png" />
            </div>
          </div>
          <div className="number">
            <div className="overlap-group">
              <div className="div">+30 211 118 7153</div>
            </div>
          </div>
          <a
            className="e-mail-info-nmcp-gr"
            href="mailto:info@nmcp.gr"
            rel="noopener noreferrer"
            target="_blank"
          >
            {" "}
            e-mail: info@nmcp.gr
          </a>
        </div>
        <div className="LOCATION">
          <div className="EDRA">
            <div className="footer-location-wrapper">
              <img className="footer-location" alt="Footer location" src="./assets/images/Footer_location.png" />
            </div>
            <p className="p">Επισκεφτείτε μας στην έδρα μας</p>
          </div>
          <div className="location">
            <p className="element-2">
              Εθνομαρτύρων 35 17341
              <br /> Άγιος Δημήτριος, Ελλάδα
            </p>
          </div>
        </div>
        <div className="OROI">
          <div className="SXETIKA">
            <div className="text-wrapper-2">Σχετικά Με Μας</div>
          </div>
          <div className="OROI-POLITIKOI">
            <div className="text-wrapper-3">
              <a href="/tos">Όροι Χρήσης</a>
              <br />
              <a href="/privacy-policy">Πολιτική Απορρήτου</a>
            </div>
          </div>
        </div>
      </footer>
      <div className="LINE">
        <img className="line" alt="Line" src="./assets/images/line.png" />
      </div>
      <div className="copyrights">
        <p className="text-wrapper-4">Copyright © NMCP 2023 | Designed by Polyvios Patseadis</p>
      </div>
    </div>
    );
}

export default Footer;
