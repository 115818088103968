import {Container, Row, Col, Button, Carousel, CardDeck, Modal} from 'react-bootstrap';
import Product from '../components/pages/Main/Product';
import {config} from '../config';
import '../css/home.css'
import '../css/header.css'
import '../css/about.css'
import '../css/services.css'
import '../css/footer.css'
import '../css/prices.css'
import '../css/main.css'
import Menu from "../components/pages/Fragments/Menu";
import Footer from "../components/pages/Fragments/Footer";
import {useSigninCheck, useUser} from "reactfire";
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import db from "../db";


function Main() {
  return (
    <>
      <div className='main'>
        <IntroFragment/>
        <AboutFragment/>
        <PricingFragment/>
        <ServiceFragment/>
        <Footer/>
      </div>
    </>
  );
}

function IntroFragment() {
  const history = useHistory();
  const {data: signInCheckResult} = useSigninCheck();
  const [showModal, setShowModal] = useState(false);
  const {data: user} = useUser();

  const fetchCustomers=async()=>{

    const docRef = db.collection("customers").doc(user.uid);

    docRef.get().then((doc) => {
      if (doc.exists) {
        console.log("Document data:", doc.data());
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    }).catch((error) => {
      console.log("Error getting document:", error);
    });
  }

  useEffect(() => {
    if (user) fetchCustomers();
  }, [user])

  const handleDemoDownload = () => {
    if (signInCheckResult.signedIn === false) {
      return history.push('/login');
    }

    if (!user.emailVerified) {
      setShowModal(true);
      return;
    }

    window.open('https://firebasestorage.googleapis.com/v0/b/nmcp-80894.appspot.com/o/SetupNMCP.exe?alt=media&token=');
  }


  return (
    <div className="home-section">
      <Menu/>
      <div className="content">
          <div className="frame">
            <div className="div">
              <div className="title">
                <p className="a-fully">
                  <span className="text-wrapper">NMCP </span>
                  <span className="span">Λογισμικό</span>
                </p>
              </div>
              <p className="p">
                Μπορείτε τώρα να γνωρίσετε το λογισμικό μας μέσω του Demo κάνοντας εγγραφή με δωρεάν δοκιμή για 5 μέρες
                μετά την ενεργοποίησή του.
              </p>
              <Button className="button" href='/#pricing'>
                <div className="text-wrapper-2">Αγορά</div>
              </Button>
              <Button className="demo-wrapper" onClick={() => handleDemoDownload()}>
                <div className="demo">Κατεβάστε Το Demo</div>
              </Button>
            </div>
            <div className="carousel-component">
              <div className="section-container">
                <Carousel>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="./assets/images/favourite.png"
                      alt="First slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="./assets/images/ALLES.png"
                      alt="First slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="./assets/images/Gov.png"
                      alt="Second slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="./assets/images/AADE.png"
                      alt="Third slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="./assets/images/clients.png"
                      alt="fourth slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="./assets/images/Database.png"
                      alt="fifth slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="./assets/images/prints.png"
                      alt="six slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="./assets/images/addclient.png"
                      alt="seven slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="./assets/images/editclient.png"
                      alt="eight slide"
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
          </div>
        
        </div>
      </div>
    )
}

function AboutFragment() {
  return (
    <div className="about-section" >
    <div className="frame">
      <div className="illustrator">
        <img className="element" alt="Element" src="./assets/images/about_illu.png" />
      </div>
      <div className="text">
        <div className="heading">
          <img className="icon" alt="Icon" src="./assets/images/papaki.png" />
          <div className="title">
            <div className="text-wrapper">Γνωρίστε το λογισμικό μας</div>
          </div>
        </div>
        <img className="line" alt="Line" src="./assets/images/line.png" />
        <p className="NMCP-copy-paste">
          <span className="span">
            Το λογισμικό NMCP είναι σχεδιασμένο να κάνει πιο παραγωγική την επιχείρησή σας. Δηλαδή πρόσβαση σε
            υπηρεσίες με μόνο ένα κλικ. Σας γλιτώνει από τα χρονοβόρα copy-paste, σας γλιτώνει από πολλές ώρες
            εργασίας, μπορείτε να εξυπηρετείτε πολλαπλούς πελάτες ταυτόχρονα ανοίγοντας πολλαπλά παράθυρα σε πολύ
            άμεσο χρόνο μέσω του Chrome και του Μοzzila. Επίσης σας προσφέρει όλες τις εκτυπώσεις Ε1, Ε2, Ε3, Ε9 και
            εκκαθαριστικό μόνο με ένα κλικ. Όσο για τους πελάτες σας, όλοι είναι αποθηκευμένοι σε μια τοπική βάση
            δεδομένων στον υπολογιστή σας, απλά επιλέγετε τον πελάτη και την ανάλογη υπηρεσία που θέλετε να
            συνδεθείτε. Επιπλέον άμα έχετε ένα ή παραπάνω προγράμματα στον χώρο σας μπορείτε να κάνετε κοινόχρηστη την
            βάση δεδομένων σας ανά πάσα στιγμή με ένα κουμπί, αφού πρώτα ακολουθήστε τα παρακάτω βήματα στο{" "}
          </span>
          <a
            href="https://raw.githubusercontent.com/N-M-C-P/Versions/main/NMCP%20-%20manual.pdf"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span className="text-wrapper-2">Εγχειρίδιο χρήσης</span>
          </a>
          <span className="span">.</span>
        </p>
      </div>
    </div>
  </div>
  );
}


function PricingFragment() {
  const [showModal, setShowModal] = useState(false);


  const EmailVerificationModal = () => {
    return (<Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Ειδοποίηση</Modal.Title>
      </Modal.Header>
      <Modal.Body>Επιβεβαιώστε την διεύθυνση ηλεκτρονικού ταχυδρομείου σας για να προχωρήσετε.
        (Ελέγξτε το φάκελο spam). Μετά την Επιβεβαίωση του ηλεκτρονικού ταχυδρομείου σας κάντε refresh την σελίδα.</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>)
  }

  return (
    <div className="pricing-section" id='pricing'>
      <div className="heading-content">
        <div className="heading">
          <img className="icon" alt="Icon" src="./assets/images/dollar.png" />
          <div className="title">
            <p className="text-wrapper">Τα ευέλικτα προγράμματα τιμολόγησης μας</p>
          </div>
        </div>
      </div>
        <div className="cards">
          <CardDeck>{config[process.env.REACT_APP_ENV].product.prices.map((price, index) => <Product key={index} price={price} setShowModal={setShowModal} />)}</CardDeck>
          <EmailVerificationModal />
        </div>
        
      </div>
  );
}

//src="./assets/images/Dinatotites.png"
//src="./assets/images/oikonomika_illu.png"
//src="./assets/images/easy_illu.png"
//src="./assets/images/support_illu.png"

function ServiceFragment() {
  return (
    <div className="services-section" id='abilities'>
      <div className="heading">
        <img className="icon" alt="Icon" src="./assets/images/Dinatotites.png" />
        <div className="title">
          <div className="text-wrapper">Δυνατότητες</div>
        </div>
      </div>
      <div className="content">
        <div className="element">
          <div className="div">
            <img className="img" alt="Element" src="./assets/images/oikonomika_illu.png" />
            <div className="div-wrapper">
              <div className="text">
                <div className="text-wrapper-2">Διαχείριση οικονομικών</div>
                <p className="p">
                  Μπορείτε από τον λογαριασμό σας ανά πάσα στιγμή να διαχειριστείτε τα οικονομικά σας και τις συνδρομές
                  σας. Για παράδειγμα να ανανεώσετε την συνδρομή σας ώστε να έχετε παραπάνω από μία χρήση, να αλλάξετε
                  την συνδρομή σας ώστε από μηνιαία να γίνει χρονική και το αντίθετο ή αλλιώς να την ακυρώσετε. Σε
                  περίπτωση που ακυρώσετε την συνδρομή σας η συνδρομή θα είναι ενεργή για το χρονικό όριο που έχετε
                  πληρώσει.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="element">
          <div className="content-2">
            <img className="img" alt="Element" src="./assets/images/easy_illu.png" />
            <div className="content-3">
              <div className="text-2">
                <div className="text-wrapper-3">Εύκολο στην χρήση</div>
                <p className="p">
                  Το λογισμικό μας περιέχει ένα περιβάλλον που είναι εύχρηστο, γρήγορο και φιλικό προς τον χρήστη.
                  Υπάρχουν πολλές και απλές λειτουργίες για τον χρήστη όπως και κατάλληλα κείμενα για καλύτερη κατανόηση
                  του προγράμματος/λογισμικού. Δείτε αναλυτικά παρακάτω στην συλλογή το λογισμικό μας.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="element">
          <div className="content-4">
            <img className="img" alt="Element" src="./assets/images/support_illu.png" />
            <div className="content-5">
              <div className="text-2">
                <div className="text-wrapper-3">Yποστήριξη</div>
                <p className="p">
                  Για κάθε δυσλειτουργία που συναντάτε στο λογισμικό μας μπορείτε να επικοινωνήσετε μαζί μας μέσω
                  ηλεκτρονικού ταχυδρομείου στο support@nmcp.gr και θα σας βοηθήσουμε το συντομότερο δυνατόν.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
